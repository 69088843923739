<script>
import { Bar } from "vue-chartjs";
export default {
  extends: Bar,
  props: ["labels","datasets"],
  mounted() {
    const labels = this.labels;
    const datasets = this.datasets;
    this.renderChart(
      {
        labels,
        datasets,
      },
      { responsive: true, maintainAspectRatio: false }
    );
  }
};
</script>
