<template>
  <div>
    <v-container>
      <v-card>
        <v-toolbar color="#0f2e8c" flat>
          <h3 class="white--text">Báo cáo chuyến hàng: {{shipment.Code}} </h3>
          <v-spacer />
          <v-btn
            dark
            color="green"
            :disabled="downloadState"
            :loading="downloadState"
            @click="qrConfirmExport"
          >
            Tải báo cáo Excel
          </v-btn>
        </v-toolbar>
        <v-card-title>
          <v-row>
            <v-col
              cols=4
            >
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle class="text-h5">
                    <strong>Tổng: </strong> <span class="blue--text">{{ DoiTien(TongTienCuoc) }}</span>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle class="text-h5">
                    <strong>Đã thanh toán: </strong> <span class="green--text">{{ DoiTien(TongTienCuocDaTra) }}</span>
                  </v-list-item-subtitle>
                  <v-list-item-action class="text-h5"><v-divider /></v-list-item-action>
                  <v-list-item-subtitle  class="text-h5">
                    <strong>Dư nợ: </strong>  <span class="red--text">{{ TienCuocConNo }}</span>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col
              cols="3"
            >
              <v-select
                v-if="tab === 'thongtin'"
                v-model="TinhTrangTT"
                :items="LocThanhToan"
                item-value="_id"
                item-text="Code"
                label="Lọc tình trạng thanh toán"
              />
            </v-col>
            <v-col
              cols="3"
            >
              <v-autocomplete
                v-if="tab === 'thongtin'"
                v-model="search"
                :items="FilterSaleList"
                item-value="_id"
                item-text="Code"
                label="Lọc theo tên sale"
              />
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-tabs
          v-model="tab"
          background-color="#0f2e8c"
          center-active
          dark
        >
          <v-tab href="#thongtin">Thông tin</v-tab>
          <v-tab href="#bieudo">Biểu đồ</v-tab>
        </v-tabs>
          <v-tabs-items v-model="tab"  v-if="loading === false">
            <v-tab-item
              value="thongtin"
            >
              <template v-if="reports.length > 0">
                <v-data-table
                  :loading="loading"
                  :headers="headers"
                  :items="DanhSachMoi"
                  :search="search"
                  loading-text="Đang tải dữ liệu..."
                  :footer-props="{itemsPerPageText:'Dữ liệu mỗi trang','items-per-page-options':[10,20,30,40,50]}"
                  :items-per-page="20"
                  height="600px"
                  fixed-header
                >
                  <template #[`item.HinhAnh`]="{item}">
                    <template v-if="!item.ReportImage.Image">
                      <span class="red--text">Chưa có hình ảnh</span>
                    </template>
                    <v-img
                        :src="ShowImage(item.ReportImage.Image)"
                        :lazy-src="ShowImage(item.ReportImage.Image)"
                        max-width="150px"
                        max-height="150px"
                        v-else
                        @click="doViewImage(item.ReportImage.Image)"
                    />
                    <v-btn
                      dark
                      color="blue"
                      x-small
                      v-if="!item.ReportImage.Confirmed"
                      @click="doToggleUploadImage(item)"
                    >
                        Tải lên hình ảnh
                    </v-btn>
                  </template>
                  <template #[`item.Cuoc`]="{item}">
                    <span class="text-h6 red--text">{{ DoiTien(item.Cuoc)}}</span>
                  </template>
                  <template #[`item.PhuThu`]="{item}">
                    <template v-if="item.PhuThu > 0">
                      <v-list-group>
                        <template v-slot:activator>
                            {{ DoiTien(item.PhuThu)}}
                        </template>
                        <template 
                          v-for="(ttPT,index) in item.Items"
                        >
                          <v-list-group
                            :key="index"
                            no-action
                            sub-group
                            v-if="ttPT.PhuThu.length > 0"
                          >
                            <template v-slot:activator>
                              <v-list-item-content>
                                <v-list-item-title>{{ ttPT.Code }}</v-list-item-title>
                              </v-list-item-content>
                            </template>

                            <v-list-item
                              v-for="(dlPT, i) in ttPT.PhuThu"
                              :key="i"
                              link
                            >
                              <v-list-item-title>{{dlPT.Muc}}</v-list-item-title>
                              <v-list-item-action-text>
                                {{DoiTien(dlPT.Phi)}}
                              </v-list-item-action-text>
                            </v-list-item>
                          </v-list-group>
                        </template>
                      </v-list-group>
                    </template>
                    <template v-else>
                      {{ DoiTien(item.PhuThu)}}
                    </template>
                  </template>
                  <template #[`item.CuocSale`]="{item}">
                    {{ DoiTien(item.CuocSale)}}
                  </template>
                  <template #[`item.LoiNhuan`]="{item}">
                    {{ DoiTien(item.LoiNhuan)}}
                  </template>
                  <template #[`item.XacNhan`]="{item}">
                    <v-btn
                      dark
                      :loading="XNState === item.Sale.Username"
                      :disabled="XNState === item.Sale.Username"
                      :color="item.ReportImage.Confirmed ? 'green':'red'"
                      @click="doXNConfirm(item,!item.ReportImage.Confirmed)"
                    >
                      {{item.ReportImage.Confirmed ? 'Đã xác nhận':'Chưa xác nhận'}}
                    </v-btn>
<!--                    <v-switch-->
<!--                        :value="item.ReportImage.Confirmed"-->
<!--                        :label="item.ReportImage.Confirmed ? 'Đã xác nhận':'Chưa xác nhận'"-->
<!--                        color="green"-->
<!--                        @change="XacNhan(item,!item.ReportImage.Confirmed)"-->
<!--                        :input-value="item.ReportImage.Confirmed"-->
<!--                        :success="item.ReportImage.Confirmed"-->
<!--                        :class="item.ReportImage.Confirmed ? 'text-h6':false"-->
<!--                        :disabled="loginInfo._perm !== 'admin' && loginInfo._perm !== 'manager'"-->
<!--                    />-->
                  </template>
                  <template #[`item.TrangThai`]="{item}">
                    <v-btn
                        dark
                        :loading="TTState === item.Sale.Username"
                        :disabled="TTState === item.Sale.Username"
                        :color="item.Paid ? 'blue':'red'"
                        @click="ThanhToan(item,!item.Paid)"
                    >
                      {{item.Paid ? 'Đã thanh toán':'Chưa thanh toán'}}
                    </v-btn>
<!--                    <v-switch-->
<!--                      :value="item.Paid"-->
<!--                      :label="item.Paid ? 'Đã thanh toán':'Chưa thanh toán'"-->
<!--                      color="green"-->
<!--                      @change="ThanhToan(item,!item.Paid)"-->
<!--                      :input-value="item.Paid"-->
<!--                      :success="item.Paid"-->
<!--                      :class="item.Paid ? 'text-h6':false"-->
<!--                    />-->
                  </template>
                </v-data-table>
              </template>
              <v-alert
                border="top"
                color="red lighten-2"
                dark
                v-else
              >
                Xin lỗi! hiện tại chưa có thông tin báo cáo cho chuyến hàng này
              </v-alert>
            </v-tab-item>
            <v-tab-item
              value="bieudo"
            >
              <BarChart :labels="labels" :datasets="datasets" />
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>
    </v-container>
    <v-file-input
        ref="uploader"
        v-model="SaleImageData"
        accept="image/*"
        @change="doUploadImage"
        class="hide"
    />
    <LightBox
        ref="lightbox2"
        :media="Media"
        :show-light-box="false"
    />
  </div>
</template>

<script>

import BarChart from "@/components/BarChart.vue";
import Global from '@/plugins/query/global'
import GlobalConfig from '@/global'
import Query from '@/plugins/query/report'
import { Status } from '@/plugins/helper/dstatus'
import { DoiTien } from '@/plugins/helper/string'
import { CountTotal } from '@/plugins/helpers'
import { Cuoc,CuocGoc } from '@/plugins/helper/tinhtien.js'
import {mapGetters} from "vuex";
import {Request} from "@/plugins/helper/fetch";
import LightBox from 'vue-it-bigger'
import('vue-it-bigger/dist/vue-it-bigger.min.css') // when using webpack

export default {
  name: 'report',
  components: {
    BarChart,
    LightBox,
  },
  data () {
    return {
      Media: [],
      req: new Request('shipment'),
      UploadSaleReportImageState: null,
      SaleUploadID: null,
      SaleImageData: null,
      TTState: null,
      XNState: null,
      tab:'thongtin',
      CustomPrice:0, 
      DongGia: false,
      labels: [],
      data_kl: [],
      notice: new Status(this.$swal),
      shipmentID: undefined,
      loading: false,
      shipment:false,
      reports: [],
      itemsPerPage: 8,
      itemsPerPageArray: [4, 8, 12],
      search: '',
      filter: {},
      TinhTrangTT: null,
      LocThanhToan: [
          {_id:null,Code:'Tất cả'},
          {_id:true,Code:'Đã thanh toán'},
          {_id:false,Code:'Chưa thanh toán'},
        ],
      sortDesc: false,
      page: 1,
      headers: [
        { text: '#', value: 'index', align: 'center',width: "50px",class:"grey lighten-2 black--text" },
        { text: 'Sale', value: 'Sale.Username', align: 'center',width: "100px",class:"grey lighten-2 black--text" },
        { text: 'Tổng KL', value: 'TongKL', align: 'center',width: "100px",class:"grey lighten-2 black--text" },
        { text: 'Cước gốc', value: 'CuocGoc', align: 'center',width: "100px",class:"grey lighten-2 black--text" },
        { text: 'Tiền theo cước gốc', value: 'Cuoc', align: 'center',width: "100px",class:"grey lighten-2 black--text" },
        { text: 'Tiền theo cước bán', value: 'CuocSale', align: 'center',width: "100px",class:"grey lighten-2 black--text" },
        { text: 'Phụ thu', value: 'PhuThu', align: 'center',width: "100px",class:"grey lighten-2 black--text" },
        { text: 'Lợi nhuận', value: 'LoiNhuan', align: 'center',width: "100px",class:"grey lighten-2 black--text" },
        { text: 'Hình ảnh', value: 'HinhAnh', align: 'center',width: "100px",class:"grey lighten-2 black--text" },
        { text: 'Trạng thái', value: 'TrangThai', align: 'center',width: "100px",class:"grey lighten-2 black--text" },
        { text: 'Xác nhận', value: 'XacNhan', align: 'center',width: "100px",class:"grey lighten-2 black--text" },
      ],
      TongTienCuoc: 0,
      TongTienCuocDaTra: 0,
      downloadState: false,
    }
  },
  computed: {
    ...mapGetters(['authStatus','isAuthenticated','loginInfo']),
    ShipmentID(){
      return this.$route.params.shipmentID;
    },
    FilterSaleList(){
      return [{_id:'',Code:'Tất cả'}].concat(this.labels.map(item=>{
        return {_id:item,Code:item}
      }))
    },
    datasets(){
      return [
        {
          label: "Khối lượng bán (100g)",
          backgroundColor: "#0f2e8c",
          data: this.data_kl
        }
      ]
    },
    DanhSachMoi: function(){
      this.ResetCounter()
      return this.reports.map(
        (item, index) => {
            const TongPhuThu = parseInt(this.TongPhuThu(index))
            const TongKL = this.TongKL(index);
            const TongCuoc = this.TongCuoc(index);
            this.AddSale(item.Sale.Username,index)
            this.TienCuocTong(TongCuoc)
            // const CuocSale = this.TongCuocSale(index)+TongPhuThu
            const CuocSale = this.TongCuocSale(index);
            const LoiNhuan = CuocSale-TongCuoc
            if(item.Paid) this.TienCuocTong(TongCuoc,true)
          if(this.TinhTrangTT === null || item.Paid === this.TinhTrangTT){
            const r = {
              ...item,
              index: index+1,
              CuocGoc: this.DoiTien(CuocGoc({shipment:this.shipment,sale:item.Sale})),
              TongKL,
              Cuoc: TongCuoc,
              CuocSale: CuocSale,
              LoiNhuan: LoiNhuan,
              PhuThu: TongPhuThu,
            }
            return r
          }
        }).filter(x => x);
    },
    numberOfPages () {
      return Math.ceil(this.reports.length / this.itemsPerPage)
    },
    TienCuocConNo(){
      return this.DoiTien(this.TongTienCuoc-this.TongTienCuocDaTra)
    },
    statics(){
      return `${GlobalConfig.staticTOP}/bao-cao-shipment`;
    },
  },
  watch:{
  },
  methods: {
    ...Global,
    ...Query,
    DoiTien:DoiTien,
    doViewImage(item){
      const image = this.ShowImage(item);
      if(image) {
        this.Media = [{
          type: 'image',
          src: image,
          thumb: image,
        }];
        this.$refs.lightbox2.showImage(0);
      }
    },
    doToggleUploadImage(item){
      this.SaleUploadID = item.Sale._id;
      this.SaleImageData = null;
      this.$refs.uploader.$refs.input.click();
    },
    doUploadImage(){
      if (this.SaleImageData) {
        this.UploadSaleReportImageState = this.SaleUploadID;
        return this.req.UploadFile(`/image-report-sale/${this.shipment.id}?Sale=${this.SaleUploadID}`, this.SaleImageData).then(resp=>resp.data).then(json=>{
          const {success, error} = json;
          if(success){
            this.SaleImageData = null;
            this.notice.success("Đã tải lên hình ảnh thành công !!!");
            return this.FetchAgain(this.shipment.id).then(this.LayDuLieu);
          }
          if(error){
            this.notice.error(error);
          }
        }).finally(()=>this.UploadSaleReportImageState = null);
      } else {
        this.notice.error('Vui lòng chọn hình ảnh để tải lên !!!');
      }
    },
    doExportExcel(){

    },
    ShowImage(image){
      return `${this.statics}/${image}`;
    },
    ResetCounter(){
      this.TongTienCuoc = 0; this.TongTienCuocDaTra = 0; this.labels = []; 
      // this.datasets.data = []; 
    },
    AddSale(sale,index){
      this.labels.push(sale)
      const tongkl = parseFloat(CountTotal(this.reports[index].Items,'W')*10).toFixed(2)
      this.data_kl.push(tongkl)
    },
    TienCuocTong(sl,datra=false){
      if(datra === true){
        this.TongTienCuocDaTra += sl
      }else{
        this.TongTienCuoc += sl
      }
    },
    nextPage () {
      if (this.page + 1 <= this.numberOfPages) this.page += 1
    },
    formerPage () {
      if (this.page - 1 >= 1) this.page -= 1
    },
    updateItemsPerPage (number) {
      this.itemsPerPage = number
    },
    doXNConfirm(item, status){
      if(this.loginInfo._perm !== 'admin' && this.loginInfo._perm !== 'manager') return;
      const app = this;
      return this.notice.confirm("Xác nhận ? ", "Bạn có chắc chắn thực hiện hành động này ?").then(confirm=>{
        if(confirm){
          return app.XacNhan(item, status);
        }
      });
    },
    XacNhan(item,status){
      this.XNState = item.Sale.Username;
      const newData = {
        Status: status,
        SaleID: item.Sale._id,
      }
      this.TT('status-confirm-report/'+this.shipment.id,newData,true).then(json=>{
        if(json.success !== true)
        {
          const e = json.error || "Không thể xác nhận !!!"
          this.notice.error(e)
        }else{
          this.FetchAgain(this.shipment.id).then(this.LayDuLieu);
        }
      })
      .catch(e=>{
        this.notice.error(e)
      })
      .finally(()=>{
        this.loading = true;
        this.XNState = null;
      })
    },
    ThanhToan(item,status){
      this.TTState = item.Sale.Username;
      const newData = {
        Status: status,
        ID: item.index-1,
      }
      this.TT('thanh-toan/'+this.shipmentID,newData).then(json=>{
        if(json.success !== true)
        {
          const e = json.error || "Không thể cập nhật tình trạng thanh toán !!!"
          this.notice.error(e)
        }else{
          this.LayDuLieu()
        }
      })
      .catch(e=>{
        this.notice.error(e)
      })
      .finally(()=>{
        this.loading = true;
        this.TTState = null;
      })
    },
    TongKL(index){
      return CountTotal(this.reports[index].Items,'W')
    },
    TongPhuThu(index){
      return CountTotal(this.reports[index].Items,'TongPhuThu')
    },
    TongCuoc(index){
      // const GiaGoc = parseInt(this.reports[index].Sale.Shipment)
      let TongCuoc = 0;
      const sale = this.reports[index].Sale;
      this.reports[index].Items.map(item=>{
        const {GiaGoc} = Cuoc({item,shipment:this.shipment,sale});
        TongCuoc += GiaGoc;
        // const GiaVon = parseInt(item.CustomShipment.Von)
        // if(GiaVon === 0){
        //   TongCuoc += parseFloat(item.W)*GiaGoc
        // }else{
        //   TongCuoc += parseFloat(item.W)*parseInt(GiaVon)
        // }
      })
      return TongCuoc
    },
    TongCuocSale(index){
      let TongCuoc = 0
      // const CuocGocSale = parseInt(this.reports[index].Sale.Shipment)
      const sale = this.reports[index].Sale;
      this.reports[index].Items.map(item=>{
        // const GiaVon = parseInt(item.CustomShipment.Sale)
        // const GiaGoc = (CuocGocSale > parseInt(item.Sale) ) ? CuocGocSale:parseInt(item.Sale)
        // const Cuoc = (GiaVon === 0) ? parseFloat(item.W)*GiaGoc:parseFloat(item.W)*parseInt(GiaVon)
        const {GiaSale} = Cuoc({item,shipment:this.shipment,sale});
        TongCuoc += GiaSale
      })
      return TongCuoc
    },
    LoiNhuan(index){
      return parseInt(this.TongCuocSale(index))-parseInt(this.TongCuoc(index))
    },
    LayDuLieu() {
      this.loading = true
      Promise.all([
        this.Tai(),
      ]).finally(()=>{
        this.loading = false
      })
    },
    Tai(){
      return this.Report(this.shipmentID).then(resp=>{
        if(resp.success === true){
          this.CustomPrice = resp.data.CustomPrice;
          this.DongGia = resp.data.DongGia;
          this.shipment = resp.data.Shipment;
          this.reports = resp.data.Report;
        }else{
          const msg = resp.error || "Không thể tải thông tin báo cáo"
          this.notice.error(msg)
          this.$router.push('/')
        }
      })
      .catch(e=>{
        this.notice.error(e)
      })
    },
  },
  created(){
    this.shipmentID = this.$route.params.shipmentID
    this.LayDuLieu()
  }
}
</script>

<style scoped>
.hide {
  visibility: hidden !important;
}
</style>