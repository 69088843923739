import { Request } from '@/plugins/helper/fetch'
const req = new Request('report')
const reqShipment = new Request('shipment')
async function TT(id,newData,shipment=false)
{
  const request = shipment ? reqShipment:req;
  const resp = await request.update(id,newData)
  return await resp.json()
}
async function FetchAgain(id)
{
  const resp = await reqShipment.get(`report/${id}`)
  return await resp.json()
}
function qrConfirmExport(){
  this.downloadState = true;
  return req.get(`export/${this.ShipmentID}`).then(response=>{
    const contentType = response.headers.get("content-type");
    if (contentType && contentType.indexOf("application/json") !== -1) {
      return response.json().then(json => {
        const {error} = json;
        if(error){
          this.notice.error(error);
        }
      });
    } else {
      return response.blob().then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `Report-${this.shipment.Code}.xlsx`;
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
    }
  }).finally(()=>this.downloadState=false);
}
export default {
  TT,
  FetchAgain,
  qrConfirmExport,
}