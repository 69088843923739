import axios from 'axios';
import { API } from './helper/api.js'
// import {pad} from './helper/string';
export const Request = (token,store)=>{
    let _request = axios.create({
        baseURL: API,
        timeout: 60000,
        headers: {'Authorization': token}
    });
    _request.interceptors.response.use(undefined, err => {
        const error = err.response;
        if (error.status===401){
            store.dispatch('AUTH_LOGOUT');
        }
    });
    return _request;
}
export function CountTotal(packages,field='GW') {
    let total = 0
    packages.map(item=>{
        total = (total*1)+(item[field]*1)
    })
    // return total
    return total.toFixed(2)
}
export function CountQuantity(items) {
    let total = 0
    items.map(()=>{
        total +=1
    })
    return total
}