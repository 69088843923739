export const Cuoc = ({item,shipment,sale}) =>{
  let {CustomShipment,Customer} = item;
  const ShipmentData = (item.Shipment) ? item.Shipment:shipment;
  if(CustomShipment === undefined) {
    CustomShipment = {
      Von: 0,
      Sale: 0,
    };
  }
  let _CustomPrice = 0; let _DongGia = false;
  let {CustomPrice,DongGia} = ShipmentData;
  if(CustomPrice) _CustomPrice = parseInt(CustomPrice);
  if(DongGia) _DongGia = DongGia;
  let _TongPhuThu = (item.TongPhuThu) ? item.TongPhuThu:0;
  const KL = (item.GW) ? item.GW*1:item.W*1;
  let {Von, Sale} = CustomShipment;
  let CustomerShipmentAir = Customer.Shipment;
  let CustomerShipmentSea = Customer.ShipmentSea;
  let SaleShipmentAir = 0; let SaleShipmentSea = 0;
  if(Customer.Sale){
    SaleShipmentAir = Customer.Sale.Shipment;
    SaleShipmentSea = Customer.Sale.ShipmentSea;
  }else{
    SaleShipmentAir = sale.Shipment;
    SaleShipmentSea = sale.ShipmentSea;
  }
  let SaleShipment = (ShipmentData.Type === "ship") ? SaleShipmentSea:SaleShipmentAir;
  let CustomerShipment = (ShipmentData.Type === "ship") ? CustomerShipmentSea:CustomerShipmentAir;
  let Min = SaleShipment;
  if(Von === undefined) {Von = 0;}
  if(Sale === undefined) {Sale = 0;}
  if(Von === 0) Von = SaleShipment;
  if(Sale === 0){
    Sale = CustomerShipment;
  }
  Sale = Sale*1; Von = Von*1;
  // console.log('Sale:',Sale);
  if(_CustomPrice > 0){
    if(_DongGia){
      Von = _CustomPrice;
    }else{
      if(Von < _CustomPrice){
        Von = _CustomPrice;
      }
    }
  }
  if(CustomShipment.Von > 0) Von = CustomShipment.Von*1;
  if(CustomShipment.Sale > 0) Sale = CustomShipment.Sale*1;
  Min = Von;
  // console.log(ShipmentData);
  // console.log({Sale,Von,GiaGoc,GiaSale,Phi,Min});
  if(Sale <= Von) Sale = Von;
  const GiaGoc = Von*KL*1; const GiaSale = (Sale*KL*1)+_TongPhuThu; const Phi = Sale;
  return {Sale,Von,GiaGoc,GiaSale,Phi,Min};
}
export const CuocGoc = ({shipment,sale}) =>{
  let SaleShipmentAir = 0; let SaleShipmentSea = 0;
  SaleShipmentAir = sale.Shipment;
  SaleShipmentSea = sale.ShipmentSea;
  let Cuoc = (shipment.Type === "ship") ? SaleShipmentSea:SaleShipmentAir;
  if(Cuoc < shipment.CustomPrice) Cuoc = shipment.CustomPrice;
  if(shipment.DongGia) Cuoc = shipment.CustomPrice;
  return Cuoc*1;
}
export const DoiCuoc = (Cuoc,VN=true) =>{
  const style = VN ? {locale:'vi-VN', currency: 'VND'} : {locale:'ja-JP', currency: 'JPY'};
  Cuoc = isNaN(Cuoc*1) ? 0:Cuoc*1;
  return new Intl.NumberFormat(style.locale, { style: 'currency', currency: style.currency }).format(Cuoc)
}